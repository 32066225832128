import React, { useState } from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavbarText,
  Button,
} from 'reactstrap';
import Instagram from './svg/Instagram.svg';
import Facebook from './svg/Facebook.svg';

const NavBar = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <div>
      <Navbar color='light' light expand='md'>
        <NavbarBrand href='/'>elektriskSparkesykkel</NavbarBrand>

        <NavLink>
          <div>
            <a href='mailto: elektrisksparkesykkel@gmail.com'>We are hiring!</a>
          </div>
        </NavLink>
        <NavLink>
          <div>
            <a href='https://www.instagram.com/electricscootr/'>
              <img src={Instagram} />
            </a>
          </div>
        </NavLink>
        <NavLink>
          <div>
            <a href='https://www.facebook.com/ektrisksparkesykkel/'>
              <img src={Facebook} />
            </a>
          </div>
        </NavLink>
      </Navbar>
    </div>
  );
};

export default NavBar;
