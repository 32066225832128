import React, { Component } from 'react';

// Ansatte bilder:
import Mathias from './svg/Mathias.svg';
import Sigurd from './svg/Sigurd.svg';
import Sindre from './svg/Sindre.svg';
import Andrea from './svg/Andrea.svg';
import Håvard from './svg/Håvard.svg';
import Stian from './svg/Stian.svg';
import Marte from './svg/Marte.svg';
import Eirik from './svg/Eirik.svg';
import Amir from './svg/Amir.svg';
import Anna from './svg/Anna.svg';
import Sverre from './svg/Sverre.svg';
import Lars from './svg/Lars.svg';
import Christine from './svg/Christine.svg';
import Olav from './svg/Olav.svg';

import './ansatte.css';

class Ansatte extends Component {
  static defaultProps = {
    ansatte: [
      {
        name: 'Mathias Rødland',
        position: 'Chief financial officer (CFO)',
        office: 'Trondheim',
        src: Mathias,
        alt: 'Mathias Rødland',
        link: 'matproduksjon',
      },
      {
        name: 'Sigurd Vaagland',
        position: 'Chief marketing officer (CMO)',
        office: 'Trondheim',
        src: Sigurd,
        alt: 'Sigurd Vaagland',
        link: 'renthav',
      },
      {
        name: 'Sindre Reinholt',
        position: 'Chief human resources (CHRMO)',
        office: 'Trondheim',
        src: Sindre,
        alt: 'Sindre Reinholt',
        link: 'renthav',
      },
      {
        name: 'Andrea Bratsberg',
        position: 'Chief science data analyst',
        office: 'Trondheim',
        src: Andrea,
        alt: 'Andrea Bratsberg',
        link: 'renthav',
      },
      {
        name: 'Andrea Bratsberg',
        position: 'Chief sales officer',
        office: 'Trondheim',
        src: Håvard,
        alt: 'Håvard Aufles',
        link: 'renthav',
      },
      {
        name: 'Stian Morel',
        position: 'Chief inflencer Officer',
        office: 'Lofoten',
        src: Stian,
        alt: 'Stian Morel',
        link: 'renthav',
      },
      {
        name: 'Marte Tesdal',
        position: 'Chief ethical marketing officer (CEMO)',
        office: 'Trondheim',
        src: Marte,
        alt: 'Marte Tesdal',
        link: 'renthav',
      },
      {
        name: 'Eirik Trygstad',
        position: 'Chief technology officer',
        office: 'Oslo',
        src: Eirik,
        alt: 'Eirik Trygstad',
        link: 'renthav',
      },
      {
        name: 'Amir Asghernajad',
        position: 'Chief creative officer',
        office: 'Oslo',
        src: Amir,
        alt: 'Amir Asghernajad',
        link: 'renthav',
      },
      {
        name: 'Anna Eknes Stagrum',
        position: 'Chief science technology information officer (CSTIO)',
        office: 'Trondheim',
        src: Anna,
        alt: 'Anna Eknes Stagrum',
        link: 'renthav',
      },
      {
        name: 'Sverre Vik',
        position: 'Chief marketing officer (CMO)',
        office: 'Bergen',
        src: Sverre,
        alt: 'Sverre Vik',
        link: 'renthav',
      },
      {
        name: 'Lars Huneide Thorbjørnsen',
        position: 'Chief human resources officer (CHRO)',
        office: 'Oslo',
        src: Lars,
        alt: 'Lars Huneide Thorbjørnsen',
        link: 'renthav',
      },
      {
        name: 'Christine Jæger',
        position: 'Chief international marketing architect officer (CIMAO)',
        office: 'Palo Alto',
        src: Christine,
        alt: 'Christine Jæger',
        link: 'renthav',
      },
      {
        name: 'Olav Tyssen Bergo',
        position: 'Chief experience officer',
        office: 'Trondheim',
        src: Olav,
        alt: 'Olav',
        link: 'renthav',
      },
    ],
  };

  render() {
    return (
      <div class='container'>
        <h1 class=''>Our team:</h1>
        <br></br>
        <br></br>

        <div class='row'>
          {this.props.ansatte.map((
            a // Dette er funksjonen for å "map"e over alle hundene som finnes i data arrayen. Vi henter props fra dogs som er på app.js og deretter maper vi over og setter en div med klasse for alle hundene i arrayen. Vi sier også at hver hund er det samme som "d"
          ) => (
            <div class='col-md-4 text-center'>
              <div className='ansattBilde'>
                <img src={a.src} className='ansattSelveBilde' />
              </div>
              <div class>
                <p className='stillingTekst'>{a.name}</p>
                <p className='stillingUtfyllendeTekst'>{a.position}</p>
                <p className='stillingUtfyllendeTekst'>
                  <b>{a.office}</b>
                </p>
                <br />
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default Ansatte;
