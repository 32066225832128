import React, { Component } from 'react';
import axios from 'axios';
import {
  Carousel,
  CarouselCaption,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
} from 'reactstrap';
import './Carousel.css';
// Ansatte bilder:
import Mathias from './svg/Mathias.svg';
import Sigurd from './svg/Sigurd.svg';
import Sindre from './svg/Sindre.svg';
import Andrea from './svg/Andrea.svg';
import Håvard from './svg/Håvard.svg';
import Stian from './svg/Stian.svg';
import Marte from './svg/Marte.svg';
import Eirik from './svg/Eirik.svg';
import Amir from './svg/Amir.svg';
import Anna from './svg/Anna.svg';
import Sverre from './svg/Sverre.svg';
import Lars from './svg/Lars.svg';
import Christine from './svg/Christine.svg';
import Olav from './svg/Olav.svg';

import './ansatte.css';

const items = [];

class CarouselComponent extends Component {
  static defaultProps = {
    ansatte: [
      {
        name: 'Mathias Rødland',
        position: 'Chief financial officer (CFO)',
        office: 'Trondheim',
        src: Mathias,
        alt: 'Mathias Rødland',
        link: 'matproduksjon',
      },
      {
        name: 'Sigurd Vaagland',
        position: 'Chief marketing officer (CMO)',
        office: 'Trondheim',
        src: Sigurd,
        alt: 'Sigurd Vaagland',
        link: 'renthav',
      },
      {
        name: 'Sindre Reinholt',
        position: 'Chief human resources (CHRMO)',
        office: 'Trondheim',
        src: Sindre,
        alt: 'Sindre Reinholt',
        link: 'renthav',
      },
      {
        name: 'Andrea Bratsberg',
        position: 'Chief science data analyst',
        office: 'Trondheim',
        src: Andrea,
        alt: 'Andrea Bratsberg',
        link: 'renthav',
      },
      {
        name: 'Andrea Bratsberg',
        position: 'Chief sales officer',
        office: 'Trondheim',
        src: Håvard,
        alt: 'Håvard Aufles',
        link: 'renthav',
      },
      {
        name: 'Stian Morel',
        position: 'Chief inflencer Officer',
        office: 'Lofoten',
        src: Stian,
        alt: 'Stian Morel',
        link: 'renthav',
      },
      {
        name: 'Marte Tesdal',
        position: 'Chief ethical marketing officer (CEMO)',
        office: 'Trondheim',
        src: Marte,
        alt: 'Marte Tesdal',
        link: 'renthav',
      },
      {
        name: 'Eirik Trygstad',
        position: 'Chief technology officer',
        office: 'Oslo',
        src: Eirik,
        alt: 'Eirik Trygstad',
        link: 'renthav',
      },
      {
        name: 'Amir Asghernajad',
        position: 'Chief creative officer',
        office: 'Oslo',
        src: Amir,
        alt: 'Amir Asghernajad',
        link: 'renthav',
      },
      {
        name: 'Anna Eknes Stagrum',
        position: 'Chief science technology information officer (CSTIO)',
        office: 'Trondheim',
        src: Anna,
        alt: 'Anna Eknes Stagrum',
        link: 'renthav',
      },
      {
        name: 'Sverre Vik',
        position: 'Chief marketing officer (CMO)',
        office: 'Bergen',
        src: Sverre,
        alt: 'Sverre Vik',
        link: 'renthav',
      },
      {
        name: 'Lars Huneide Thorbjørnsen',
        position: 'Chief human resources officer (CHRO)',
        office: 'Oslo',
        src: Lars,
        alt: 'Lars Huneide Thorbjørnsen',
        link: 'renthav',
      },
      {
        name: 'Christine Jæger',
        position: 'Chief international marketing architect officer (CIMAO)',
        office: 'Palo Alto',
        src: Christine,
        alt: 'Christine Jæger',
        link: 'renthav',
      },
      {
        name: 'Olav Tyssen Bergo',
        position: 'Chief experience officer',
        office: 'Trondheim',
        src: Olav,
        alt: 'Olav',
        link: 'renthav',
      },
    ],
  };

  constructor(props) {
    super(props);
    this.state = { activeIndex: 0, name: [] };
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.goToIndex = this.goToIndex.bind(this);
    this.onExiting = this.onExiting.bind(this);
    this.onExited = this.onExited.bind(this);
  }

  onExiting() {
    this.animating = true;
  }

  onExited() {
    this.animating = false;
  }

  next() {
    if (this.animating) return;
    const nextIndex =
      this.state.activeIndex === items.length - 1
        ? 0
        : this.state.activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  }

  previous() {
    if (this.animating) return;
    const nextIndex =
      this.state.activeIndex === 0
        ? items.length - 1
        : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  }

  goToIndex(newIndex) {
    if (this.animating) return;
    this.setState({ activeIndex: newIndex });
  }

  render() {
    const { activeIndex } = this.state;
    //name.map

    const slides = this.props.ansatte.map((item) => {
      return (
        <CarouselItem
          onExiting={this.onExiting}
          onExited={this.onExited}
          // item.name
          key={item.name}
          style={{ color: 'black' }}
        >
          <div class='col-md-4 text-center'>
            <div className='ansattBilde'>
              <img src={item.src} className='ansattSelveBilde' />
            </div>
            <div class>
              <p className='stillingTekst'>{item.name}</p>
              <p className='stillingUtfyllendeTekst'>{item.position}</p>
              <p className='stillingUtfyllendeTekst'>{item.office}</p>
              <br />
            </div>
          </div>
          {/* <img
            src={item.src}
            alt={item.altText}
            style={{ width: '100%', alignItems: 'center' }}
          /> */}
          <CarouselCaption
            style={{ color: 'black' }}
            // captionText={item.name} // Her kommer Name
            // captionHeader={item.name} // Her kommer Message
          />
        </CarouselItem>
      );
    });

    return (
      <div
        style={{
          width: '100%',
          textAlign: 'center',
          boxShadow: '-1px 6px 20px 1px black',
        }}
      >
        <link
          rel='stylesheet'
          href='https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/4.1.3/css/bootstrap.min.css'
        />
        <Carousel
          infiniteLoop={true}
          useKeyboardArrows={true}
          autoPlay={true}
          activeIndex={activeIndex}
          next={this.next}
          interval={1500}
          previous={this.previous}
          style={{ height: '10px' }}
        >
          <CarouselIndicators
            items={items}
            activeIndex={activeIndex}
            onClickHandler={this.goToIndex}
            style={{ color: 'black' }}
          />
          {slides}
          <CarouselControl
            direction='prev'
            directionText='Previous'
            onClickHandler={this.previous}
          />
          <CarouselControl
            direction='next'
            directionText='Next'
            onClickHandler={this.next}
          />
        </Carousel>
      </div>
    );
  }
}

export default CarouselComponent;
