import React, { Component } from 'react';
import './vision.css';

class OurVision extends Component {
  render() {
    return (
      <div class='container'>
        <h1 class='text-center'>Our vision:</h1>
        <br></br>
        <div class='row text-center'>
          <div class='col-md'>
            <div className='values'>
              <h2>Self-service</h2>
              <p>
                Our vision is to make the scooter experience available for
                everyone by offering an easy and self-serviced application.
              </p>
            </div>
          </div>
          <div class='col-md'>
            <div className='values'>
              <h2>Sustainability</h2>
              <p>
                We believe that a sustainable world demands technology that can
                connect scooters all around the world.
              </p>
            </div>
          </div>
          <div class='col-md'>
            <div className='values'>
              <h2>Let it be employees!</h2>
              <p>
                We're growing, and we're{' '}
                <a href='mailto: elektrisksparkesykkel@gmail.com'>hiring!</a>{' '}
                Join a team that inspires to personal growth and rewards
                innovation and talent.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default OurVision;
