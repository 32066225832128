import React, { Component } from 'react';
import axios from 'axios';
import {
  Carousel,
  CarouselCaption,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
} from 'reactstrap';
import './Carousel.css';

const items = [];

class CarouselComponent extends Component {
  componentDidMount() {
    axios
      .get(`https://api.elektrisksparkesykkel.com/api/v1/messages`)
      .then((res) => {
        const name = res.data;
        const message = res.data;
        // const message = res.data;
        this.setState({ name, message });
        console.log(name);
      });
  }

  constructor(props) {
    super(props);
    this.state = { activeIndex: 0, name: [] };
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.goToIndex = this.goToIndex.bind(this);
    this.onExiting = this.onExiting.bind(this);
    this.onExited = this.onExited.bind(this);
  }

  onExiting() {
    this.animating = true;
  }

  onExited() {
    this.animating = false;
  }

  next() {
    if (this.animating) return;
    const nextIndex =
      this.state.activeIndex === items.length - 1
        ? 0
        : this.state.activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  }

  previous() {
    if (this.animating) return;
    const nextIndex =
      this.state.activeIndex === 0
        ? items.length - 1
        : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  }

  goToIndex(newIndex) {
    if (this.animating) return;
    this.setState({ activeIndex: newIndex });
  }

  render() {
    const { activeIndex } = this.state;
    //name.map

    const slides = this.state.name.map((item, i) => {
      return (
        <CarouselItem
          onExiting={this.onExiting}
          onExited={this.onExited}
          // item.name
          key={i}
          style={{ color: 'black' }}
        >
          <div
            style={{
              backgroundColor: '#1a2b1b',
              height: '100px',
              width: '100%',
            }}
            key={i}
          >
            <h1 style={{ color: 'white' }}>{item.name}</h1>
            <h2 style={{ color: 'white' }}>{item.message}</h2>
          </div>
          {/* <img
            src={item.src}
            alt={item.altText}
            style={{ width: '100%', alignItems: 'center' }}
          /> */}
          <CarouselCaption
            style={{ color: 'black' }}
            // captionText={item.name} // Her kommer Name
            // captionHeader={item.name} // Her kommer Message
          />
        </CarouselItem>
      );
    });

    return (
      <div
        style={{
          width: '100%',
          textAlign: 'center',
          boxShadow: '-1px 6px 20px 1px black',
        }}
      >
        <link
          rel='stylesheet'
          href='https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/4.1.3/css/bootstrap.min.css'
        />
        <Carousel
          activeIndex={activeIndex}
          next={this.next}
          interval={1500}
          previous={this.previous}
          style={{ height: '10px' }}
        >
          <CarouselIndicators
            items={items}
            activeIndex={activeIndex}
            onClickHandler={this.goToIndex}
            style={{ color: 'black' }}
          />
          {slides}
          <CarouselControl
            direction='prev'
            directionText='Previous'
            onClickHandler={this.previous}
          />
          <CarouselControl
            direction='next'
            directionText='Next'
            onClickHandler={this.next}
          />
        </Carousel>
      </div>
    );
  }
}

export default CarouselComponent;
