import React, { Component } from 'react';
import Upload from './svg/Upload.svg';
import Ride from './svg/Ride.svg';
import Park from './svg/Park.svg';
import './Home.css';

class HowItWorks extends Component {
  render() {
    return (
      <div class='container'>
        <div className='howItWorksBig'>
          <div class='row'>
            <div class='col-md text-center'>
              {' '}
              <img src={Upload} style={{ height: '400px' }} />
            </div>
            <div class='col-md '>
              <div class='HowItWorks'>
                <h2>Upload</h2>
                <p>Upload your scooter and, choose your next adventure!</p>
              </div>
            </div>
          </div>

          <div class='row' style={{ paddingTop: '50px' }}>
            <div class='col-md'>
              <div class='HowItWorks'>
                <h2>Ride</h2>
                <p>
                  Ride your scooter wherever and whenever you want. Experience
                  sharing at its finest, and choose from a wide selection of
                  scooters!
                </p>
              </div>
            </div>

            <div class='col-md text-center'>
              {' '}
              <img src={Ride} style={{ height: '400px' }} />
            </div>
          </div>
          <div class='row' style={{ paddingTop: '50px' }}>
            <div class='col-md text-center'>
              {' '}
              <img src={Park} style={{ height: '400px' }} />
            </div>
            <div class='col-md '>
              <div class='HowItWorks'>
                <h2>Park</h2>
                <p>
                  When finished riding - park your scooter wherever you want,
                  and upload your location again. You are now a true traveler
                  for the future!
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className='howItWorksSmall'>
          <div class='row'>
            <div class='col-md text-center'>
              <div class='HowItWorks'>
                <h2>Upload</h2>
                <p>Upload your scooter and, choose your next adventure!</p>
              </div>
            </div>
            <div class='col-md text-center'>
              <img src={Upload} style={{ height: '200px' }} />
            </div>
          </div>

          <div class='row' style={{ paddingTop: '50px' }}>
            <div class='col-md text-center'>
              <div class='HowItWorks'>
                <h2>Ride</h2>
                <p>
                  Ride your scooter wherever and whenever you want. Experience
                  sharing at its finest, and choose from a wide selection of
                  scooters!
                </p>
              </div>
            </div>
            <div class='col-md text-center'>
              <img src={Ride} style={{ height: '200px' }} />
            </div>
          </div>
          <div class='row' style={{ paddingTop: '50px' }}>
            <div class='col-md text-center'>
              <div class='HowItWorks'>
                <h2>Park</h2>
                <p>
                  When finished riding - park your scooter wherever you want,
                  and upload your location again. You are now a true traveler
                  for the future!
                </p>
              </div>
            </div>
            <div class='col-md text-center'>
              <img src={Park} style={{ height: '200px' }} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default HowItWorks;
