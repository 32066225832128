import React, { Component } from 'react';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';

import Mathias from './svg/Mathias.svg';
import Sigurd from './svg/Sigurd.svg';
import Sindre from './svg/Sindre.svg';
import Andrea from './svg/Andrea.svg';
import Håvard from './svg/Håvard.svg';
import Stian from './svg/Stian.svg';
import Marte from './svg/Marte.svg';
import Eirik from './svg/Eirik.svg';
import Amir from './svg/Amir.svg';
import Anna from './svg/Anna.svg';
import Sverre from './svg/Sverre.svg';
import Lars from './svg/Lars.svg';
import Christine from './svg/Christine.svg';
import Olav from './svg/Olav.svg';
import './ansatte.css';

class CarouselComponent extends Component {
  static defaultProps = {
    ansatte: [
      {
        name: 'Mathias Rødland',
        position: 'Chief financial officer (CFO)',
        office: 'Trondheim',
        src: Mathias,
        alt: 'Mathias Rødland',
        link: 'matproduksjon',
      },
      {
        name: 'Sigurd Vaagland',
        position: 'Chief marketing officer (CMO)',
        office: 'Trondheim',
        src: Sigurd,
        alt: 'Sigurd Vaagland',
        link: 'renthav',
      },
      {
        name: 'Sindre Reinholt',
        position: 'Chief human resources (CHRMO)',
        office: 'Trondheim',
        src: Sindre,
        alt: 'Sindre Reinholt',
        link: 'renthav',
      },
      {
        name: 'Andrea Bratsberg',
        position: 'Chief science data analyst',
        office: 'Trondheim',
        src: Andrea,
        alt: 'Andrea Bratsberg',
        link: 'renthav',
      },
      {
        name: 'Andrea Bratsberg',
        position: 'Chief sales officer',
        office: 'Trondheim',
        src: Håvard,
        alt: 'Håvard Aufles',
        link: 'renthav',
      },
      {
        name: 'Stian Morel',
        position: 'Chief inflencer Officer',
        office: 'Lofoten',
        src: Stian,
        alt: 'Stian Morel',
        link: 'renthav',
      },
      {
        name: 'Marte Tesdal',
        position: 'Chief ethical marketing officer (CEMO)',
        office: 'Trondheim',
        src: Marte,
        alt: 'Marte Tesdal',
        link: 'renthav',
      },
      {
        name: 'Eirik Trygstad',
        position: 'Chief technology officer',
        office: 'Oslo',
        src: Eirik,
        alt: 'Eirik Trygstad',
        link: 'renthav',
      },
      {
        name: 'Amir Asghernajad',
        position: 'Chief creative officer',
        office: 'Oslo',
        src: Amir,
        alt: 'Amir Asghernajad',
        link: 'renthav',
      },
      {
        name: 'Anna Eknes Stagrum',
        position: 'Chief science technology information officer (CSTIO)',
        office: 'Trondheim',
        src: Anna,
        alt: 'Anna Eknes Stagrum',
        link: 'renthav',
      },
      {
        name: 'Sverre Vik',
        position: 'Chief marketing officer (CMO)',
        office: 'Bergen',
        src: Sverre,
        alt: 'Sverre Vik',
        link: 'renthav',
      },
      {
        name: 'Lars Huneide Thorbjørnsen',
        position: 'Chief human resources officer (CHRO)',
        office: 'Oslo',
        src: Lars,
        alt: 'Lars Huneide Thorbjørnsen',
        link: 'renthav',
      },
      {
        name: 'Christine Jæger',
        position: 'Chief international marketing architect officer (CIMAO)',
        office: 'Palo Alto',
        src: Christine,
        alt: 'Christine Jæger',
        link: 'renthav',
      },
      {
        name: 'Olav Tyssen Bergo',
        position: 'Chief experience officer',
        office: 'Trondheim',
        src: Olav,
        alt: 'Olav',
        link: 'renthav',
      },
    ],
  };
  render() {
    return (
      <div style={{ backgroundColor: 'white', textAlign: 'center' }}>
        <Carousel
          infiniteLoop
          useKeyboardArrows
          autoPlay
          showIndicators={false}
          showThumbs={false}
          showStatus={false}
          showArrows={false}
          interval={3000}
        >
          <div
            class='col-md-4 text-center'
            style={{ backgroundColor: 'white' }}
          >
            <div className='ansattBilde'>
              <img src={Mathias} className='ansattSelveBilde' />
            </div>
            <div class>
              <p className='stillingTekst'>Mathias Rødland</p>
              <p className='stillingUtfyllendeTekst'>
                Chief financial officer (CFO)
              </p>
              <p className='stillingUtfyllendeTekst'>Trondheim</p>
              <br />
            </div>
          </div>
          <div class='col-md-4 text-center'>
            <div className='ansattBilde'>
              <img src={Sigurd} className='ansattSelveBilde' />
            </div>
            <div class>
              <p className='stillingTekst'>Sigurd Vaagland</p>
              <p className='stillingUtfyllendeTekst'>
                Chief marketing officer (CMO)
              </p>
              <p className='stillingUtfyllendeTekst'>Trondheim</p>
              <br />
            </div>
          </div>
          <div class='col-md-4 text-center'>
            <div className='ansattBilde'>
              <img src={Sindre} className='ansattSelveBilde' />
            </div>
            <div class>
              <p className='stillingTekst'>Sindre Reinholt</p>
              <p className='stillingUtfyllendeTekst'>
                Chief human resources (CHRMO)
              </p>
              <p className='stillingUtfyllendeTekst'>Trondheim</p>
              <br />
            </div>
          </div>
          <div class='col-md-4 text-center'>
            <div className='ansattBilde'>
              <img src={Andrea} className='ansattSelveBilde' />
            </div>
            <div class>
              <p className='stillingTekst'>Andrea Bratsberg</p>
              <p className='stillingUtfyllendeTekst'>
                Chief science data analyst
              </p>
              <p className='stillingUtfyllendeTekst'>Trondheim</p>
              <br />
            </div>
          </div>
          <div class='col-md-4 text-center'>
            <div className='ansattBilde'>
              <img src={Stian} className='ansattSelveBilde' />
            </div>
            <div class>
              <p className='stillingTekst'>Stian Morel</p>
              <p className='stillingUtfyllendeTekst'>
                Chief influencer officer
              </p>
              <p className='stillingUtfyllendeTekst'>Lofoten</p>
              <br />
            </div>
          </div>
          <div class='col-md-4 text-center'>
            <div className='ansattBilde'>
              <img src={Marte} className='ansattSelveBilde' />
            </div>
            <div class>
              <p className='stillingTekst'>Marte Tesdal</p>
              <p className='stillingUtfyllendeTekst'>
                Chief ethical marketing officer (CEMO)
              </p>
              <p className='stillingUtfyllendeTekst'>Trondheim</p>
              <br />
            </div>
          </div>
          <div class='col-md-4 text-center'>
            <div className='ansattBilde'>
              <img src={Eirik} className='ansattSelveBilde' />
            </div>
            <div class>
              <p className='stillingTekst'>Eirik Trygstad</p>
              <p className='stillingUtfyllendeTekst'>
                Chief technology officer
              </p>
              <p className='stillingUtfyllendeTekst'>Oslo</p>
              <br />
            </div>
          </div>
          <div class='col-md-4 text-center'>
            <div className='ansattBilde'>
              <img src={Amir} className='ansattSelveBilde' />
            </div>
            <div class>
              <p className='stillingTekst'>Amir Asghernajad</p>
              <p className='stillingUtfyllendeTekst'>Chief creative officer</p>
              <p className='stillingUtfyllendeTekst'>Oslo</p>
              <br />
            </div>
          </div>
          <div class='col-md-4 text-center'>
            <div className='ansattBilde'>
              <img src={Anna} className='ansattSelveBilde' />
            </div>
            <div class>
              <p className='stillingTekst'>Anna Eknes Stagrum</p>
              <p className='stillingUtfyllendeTekst'>
                Chief science technology information officer (CSTIO)
              </p>
              <p className='stillingUtfyllendeTekst'>Trondheim</p>
              <br />
            </div>
          </div>
          <div class='col-md-4 text-center'>
            <div className='ansattBilde'>
              <img src={Sverre} className='ansattSelveBilde' />
            </div>
            <div class>
              <p className='stillingTekst'>Sverre Vik</p>
              <p className='stillingUtfyllendeTekst'>
                Chief marketing officer (CMO)
              </p>
              <p className='stillingUtfyllendeTekst'>Bergen</p>
              <br />
            </div>
          </div>
          <div class='col-md-4 text-center'>
            <div className='ansattBilde'>
              <img src={Lars} className='ansattSelveBilde' />
            </div>
            <div class>
              <p className='stillingTekst'>Lars Huneide Thorbjørnsen</p>
              <p className='stillingUtfyllendeTekst'>
                Chief human resources officer (CHRO)
              </p>
              <p className='stillingUtfyllendeTekst'>Oslo</p>
              <br />
            </div>
          </div>
          <div class='col-md-4 text-center'>
            <div className='ansattBilde'>
              <img src={Christine} className='ansattSelveBilde' />
            </div>
            <div class>
              <p className='stillingTekst'>Christine Jæger</p>
              <p className='stillingUtfyllendeTekst'>
                Chief international marketing architect officer (CIMAO)
              </p>
              <p className='stillingUtfyllendeTekst'>Palo Alto</p>
              <br />
            </div>
          </div>
          <div class='col-md-4 text-center'>
            <div className='ansattBilde'>
              <img src={Olav} className='ansattSelveBilde' />
            </div>
            <div class>
              <p className='stillingTekst'>Olav Tyssen Bergo</p>
              <p className='stillingUtfyllendeTekst'>
                Chief experience officer
              </p>
              <p className='stillingUtfyllendeTekst'>Oslo</p>
              <br />
            </div>
          </div>
        </Carousel>
      </div>
    );
  }
}

export default CarouselComponent;
