import React, { Component } from 'react';
import L from 'leaflet';
import axios from 'axios';
import { Map, TileLayer, Marker, Popup } from 'react-leaflet';
import { Card, CardText, Button } from 'reactstrap';
import elsparklocationURL from './svg/elspark-location(2).svg';
import userelsparklocationURL from './svg/userelspark-location-kopi.svg';
import MessageCardForm from './MessageCardForm';
import './Home.css';

// Her henter vi funksjonene som vi endrer på senere i app:
import { getMessages, getLocation, sendMessage } from './API';

import './Home.css';

const myIcon = L.icon({
  iconUrl: elsparklocationURL,
  iconSize: [90, 122],
}); // Her sier vi at ikonet for alle de forskjellige sparkesykkelene har svg og størrelsen disse ikonene skal ha.

const messageIcon = L.icon({
  iconUrl: userelsparklocationURL,
  iconSize: [35, 122], // Her setter vi størrelse og henter inn svg for brukerens egen sparkesykkel
});

class LeafletMap extends Component {
  state = {
    location: {
      lat: 63.446827,
      lng: 10.421906,
    },
    haveUsersLocation: false,
    zoom: 6,
    userMessage: {
      name: '',
      message: '', // Her sier vi at state for userMessage er lik null
    },
    showMessageForm: false, // Her sier vi at vi ikke kan se messageform først
    sendingMessage: false, // Her sier vi at vi ikke har sendt message
    sentMessage: false, // Her sier vi at vi ikke har sendt meldingen
    messages: [],
  }; // Her setter vi state før koden vår kjøres.

  componentDidMount() {
    getMessages().then((messages) => {
      this.setState({
        messages,
      });
    });
  } // Her sier vi at når vi har loada så vil vi sette state til å vise alle messages

  // componentDidMount() {
  //   axios
  //     .get(`https://api.elektrisksparkesykkel.com/api/v1/messages`)
  //     .then(res => {
  //       const name = res.data.length;
  //       const message = res.data.length;
  //       this.setState({ name, message });
  //     });
  // }

  showMessageForm = () => {
    this.setState({
      showMessageForm: true,
    }); // showMessageForm er her en boolean som gjør at når vi trykker på en knapp så viser vi messageForm

    getLocation().then((location) => {
      this.setState({
        location,
        haveUsersLocation: true,
        zoom: 13,
      });
    });
  }; // Her sier vi at når vi får stedet vårt fra location api så zoomer vi inn 13 på kartet

  cancelMessage = () => {
    this.setState({
      showMessageForm: false,
    });
  }; // Her sier vi at hvis vi ikke sender meldingen så vises ikke messageForm lenger

  formIsValid = () => {
    let { name, message } = this.state.userMessage; // I denne funksjonen sier vi at name og message er det samme som userMessage
    name = name.trim(); // Her sier vi at vi tar bort whitespace mellom ordene i navnet --> Slik at istedenfor " Frede " så blir det "Frede"
    message = message.trim(); // Her sier vi at vi tar bort whitespace mellom ordene i navnet --> Slik at istedenfor " Black scooter   " så blir det "Black scooter"

    const validMessage =
      name.length > 0 && // Her sier vi at valid name er større enn 0 og lik eller mindre enn 500 tegn.
      name.length <= 500 &&
      message.length > 0 && // Her sier vi at valid message er større enn 0 og lik eller mindre enn 500 tegn.
      message.length <= 500;

    return validMessage && this.state.haveUsersLocation ? true : false;
  }; // Her sier vi at hvis message er valid og at this

  formSubmitted = (event) => {
    event.preventDefault();

    if (this.formIsValid()) {
      this.setState({
        sendingMessage: true,
      }); // Her sier vi at hvis this.formIsValid så sender vi message

      const message = {
        name: this.state.userMessage.name,
        message: this.state.userMessage.message,
        latitude: this.state.location.lat,
        longitude: this.state.location.lng,
      }; // Her sier vi at alt dette er en del av messagen. Det vil si navn, message og location på kartet.

      sendMessage(message).then((result) => {
        setTimeout(() => {
          this.setState({
            sendingMessage: false,
            sentMessage: true,
          });
        }, 1000); // Her sier vi hvor lang tid det tar å sende meldingen.
      });
    }
  };

  valueChanged = (event) => {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      userMessage: {
        // Her sier vi at vi viser både tidligere state
        ...prevState.userMessage,
        [name]: value,
      },
    }));
  };

  render() {
    const position = [this.state.location.lat, this.state.location.lng];
    return (
      <div>
        <Map
          className='map'
          worldCopyJump={true}
          center={position}
          zoom={this.state.zoom}
        >
          <TileLayer
            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors and Chat location by Iconika from the Noun Project'
            url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
          />
          {this.state.haveUsersLocation ? (
            <Marker position={position} icon={myIcon}></Marker>
          ) : (
            ''
          )}
          {this.state.messages.map((message) => (
            <Marker
              key={message._id}
              position={[message.latitude, message.longitude]}
              icon={messageIcon}
            >
              <Popup>
                <p>
                  <em>{message.name}:</em> {message.message}
                </p>
                {message.otherMessages
                  ? message.otherMessages.map((message) => (
                      <p key={message._id}>
                        <em>{message.name}:</em> {message.message}
                      </p>
                    ))
                  : ''}
              </Popup>
            </Marker>
          ))}
          {!this.state.showMessageForm ? (
            <div class='container'>
              <Button
                className='message-form text-center'
                onClick={this.showMessageForm}
                color='warning'
                style={{
                  borderRadius: '50%',
                  height: '200px',
                  width: '200px',
                  backgroundColor: '#ead303',
                  boxShadow: '0px 1px 300px 300px #ead3034d',
                }}
              >
                <h1>
                  <u>Upload</u>
                </h1>
              </Button>
            </div>
          ) : !this.state.sentMessage ? (
            <MessageCardForm
              cancelMessage={this.cancelMessage}
              showMessageForm={this.state.showMessageForm}
              sendingMessage={this.state.sendingMessage}
              sentMessage={this.state.sentMessage}
              haveUsersLocation={this.state.haveUsersLocation}
              formSubmitted={this.formSubmitted}
              valueChanged={this.valueChanged}
              formIsValid={this.formIsValid}
            />
          ) : (
            <Card body className='thanks-form'>
              <CardText>
                Now you can see all the scooters available in your
                neighbourhood!
              </CardText>
            </Card>
          )}
        </Map>

        <div class='text-center'>
          <p>elektrisksparkesykkel.com</p>
        </div>
        <div></div>
        {/* <div>
          <ul></ul>
          <p> {this.state.name}</p>
        </div> */}
      </div>
    );
  }
}

export default LeafletMap;
