import React from 'react';
import {
  Card,
  CardTitle,
  CardText,
  Form,
  FormGroup,
  Label,
  Input,
} from 'reactstrap';
import Button from '@material-ui/core/Button';

const MessageCardForm = (props) => {
  return (
    <div className='MessageFormDiv'>
      <Card body className='message-form'>
        <CardTitle>
          <h3>Upload and find your ride!</h3>
        </CardTitle>

        {!props.sendingMessage &&
        !props.sentMessage &&
        props.haveUsersLocation ? (
          <Form onSubmit={props.formSubmitted}>
            <FormGroup>
              <Label for='name'>
                <p>What is the name of your scooter?</p>
              </Label>
              <Input
                onChange={props.valueChanged}
                type='text'
                name='name'
                id='name'
                placeholder='Enter scootername'
              />
            </FormGroup>
            <FormGroup>
              <Label for='Scooter information'>
                <p>How is the condition of your scooter?</p>
              </Label>
              <Input
                onChange={props.valueChanged}
                type='textarea'
                name='message'
                id='message'
                placeholder='Enter a message'
              />
            </FormGroup>
            <Button
              type='submit'
              variant='contained'
              color='primary'
              disabled={!props.formIsValid()}
            >
              Upload
            </Button>
            <Button type='cancel' color='danger' onClick={props.cancelMessage}>
              Cancel
            </Button>
          </Form>
        ) : props.sendingMessage || !props.haveUsersLocation ? (
          <video
            autoPlay
            loop
            src='https://media.giphy.com/media/H7qFlItcBdS9r53PB2/giphy.mp4'
          ></video>
        ) : (
          <CardText>Thanks for uploading your scooter!</CardText>
        )}
      </Card>
    </div>
  );
};

export default MessageCardForm;
