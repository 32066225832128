import React, { Component } from 'react';
import LeafletMap from './LeafletMap';
import Navbar from './NavBar';
import axios from 'axios';
import Carousel from './Carousel';
import ButtonStart from './Button';
import AnsatteCarousel from './AnsatteCarousel';
import Ansatte from './Ansatte';
import HowItWorks from './HowItWorks';
import './App.css';
import './Home.css';
import OurVision from './OurVision';

// Test
import TestCarousel from './TestCarousel';

class App extends Component {
  state = {
    message: [],
  };

  componentDidMount() {
    axios
      .get(`https://api.elektrisksparkesykkel.com/api/v1/messages`)
      .then((res) => {
        const name = res.data.length;
        const message = res.data;
        this.setState({ name, message });
      });
  }

  render() {
    const { message } = this.state;

    return (
      <div className=''>
        <Navbar />
        <div className='Bakgrunn'>
          <div className='ButtonStart'>
            <a href='#map'>
              <div>
                <ButtonStart />
              </div>
            </a>
          </div>
          <div className='row'>
            <div class='col-md'>
              <div className=' header'>
                <h1 className='headline'>
                  Travelers for the future:{' '}
                  <span style={{ fontSize: '4rem' }}>
                    <b>{this.state.name}</b>
                  </span>
                </h1>
              </div>
            </div>
            <div class='col-md'>
              <div className=' header'>
                <h1 className='headline'>
                  {/* Travelers for the future:{' '}
                  <span style={{ fontSize: '4rem' }}>{this.state.name}</span> */}
                </h1>
              </div>
            </div>
          </div>
        </div>
        <div class='container'>
          <div class='row'>
            <Carousel />
          </div>

          <div class='container'>
            <div class='row'>
              <div class='col-md'>
                <br></br>
                <div class='container'>
                  <h1 style={{ paddingTop: '20px' }}>How it works:</h1>
                  <br></br>
                  <h4>
                    Join our community and become one of {this.state.name}{' '}
                    individual travelers.
                  </h4>
                </div>
                <br></br>
              </div>
            </div>
            <HowItWorks />
          </div>
          <div class='container'>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <OurVision />
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <Ansatte />
          </div>
        </div>
        <div id='map'>
          <LeafletMap />
        </div>
      </div>
    );
  }
}

export default App;
